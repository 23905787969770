@media (min-width: 200px) and (max-width: 480px) {
  body .icon {
    height: 10vh;
  }
}

.icon {
  height: 10vh;
  margin: 1rem auto;
}
#icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
#contact-title{
  color: black;
  text-align: center;
  background-color: white;
  width:10vw;
  margin-left:auto;
  margin-right: auto;
}
