body {
  margin: 0;
  font-family: "hacked";
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: white;
  text-align: center;
}
h2 {
  color: white;
  text-align: center;
}

@font-face {
  font-family: "hacked";
  src: url("./assets/fonts/hacked-kerx-webfont.woff") format("woff"),
    url("./assets/fonts/hacked-kerx-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
