@media (min-width: 200px) and (max-width: 480px) {
  body .project-card {
    height: 256px;
    width: 80vw;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1rem;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  body .project-content {
    background-color: black;
    color: white;
    font-size: medium;
    padding-right: 5px;
    max-width: 78vw;
  }
  body .project-subtitle {
    background-color: black;
    color: white;
    max-width: 79vw;
  }
  body .projectPageImage {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-items: center;
    width: 90vw;
  }
  body .projectPageContent {
    color: white;
    text-align: center;
    font-size: medium;
    font-family: "Courier New", Courier, monospace;
    border-style: groove;
    border-radius: 0.5rem;
    margin-left: 10vw;
    margin-right: 10vw;
  }
  body .projectPageExtraImage {
    height: 70vh;
    width: 90vw;
    object-fit: cover;
    margin: 1rem;
  }
  body .project-card:hover {
    width: 85vw;
    height: 300px;
    box-shadow: -4px 4px 3px white;
    z-index:200;
  }
 
}
@media (max-width: 1800px) and (min-width: 600px) {
  body .project-content {
    background-color: black;
    color: white;
    max-width: 20vw;
    font-size: medium;
  }
}

.collapsible {
  z-index: 100;
  float: right;
  color: white;
  background-color: black;
  border-radius: 20px;
  position: absolute;
  right: 1px;
  bottom: 1px;
  outline: none;
  font-size: x-large;
  padding-right: 5px;
  text-decoration: dashed;
  cursor: pointer;
}
.collapsible:hover {
}

.link-url {
  color: white;
}
.project-card {
  height: 35vh;
  width: 30vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  border-radius: 4px;
  overflow: hidden;
  transition:.6s ease;
}
.project-text {
  position: absolute;
  bottom: 0;
  padding: 10px;
  font-size: larger;
  font-family: "Courier New", Courier, monospace;
  width: -webkit-fill-available;
  border-style: groove;
  border-radius: 2px;
  background-color: black;
}

.project-title {
  color: white;
  text-decoration: underline;
  background-color: black;

  font-size: large;
}
.project-subtitle {
  color: white;
  background-color: black;
  padding-bottom: 8px;
  width: 100%;
  font-size: large;
}
.project-content {
  background-color: black;
  color: white;
  padding-right: 5px;
  max-width: 20vw;
  transition: max-height 0.2s ease-out;
}
.project-card:hover {
  width: 35vw;
  height:37vh;
  box-shadow: -4px 4px 3px white;
  z-index:200;
}
.project-image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  display: block;
  background-attachment: fixed;
  overflow: hidden;
  object-fit: cover;
}

.projectPageTitle {
  color: white;
  text-align: center;
  font-size: x-large;
  margin-bottom: 1vh;
  margin-top: 1vh;
}

.projectPageImage {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-items: center;
  margin-bottom: 1vh;
}
.projectPageContent {
  color: white;
  text-align: center;
  border-style: groove;
  border-radius: 0.5rem;
  font-size: large;
  font-family: "Courier New", Courier, monospace;
  margin-left: 20vw;
  margin-right: 20vw;
  padding: 20px;
}
.projectLinks {
  display: flex;
  justify-content: center;
}
.projectPageLink {
  color: white;
  font-family: "Courier New", Courier, monospace;
  margin:auto;
  
  
}
.projectPageImageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20vh;
  flex-wrap: wrap;
}
.projectPageExtraImage {
  height: 70vh;

  margin: 1rem;
}
.iconLink {
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  
}
.back-button {
  background-color: black;
  color: white;
  border-style: groove;
  border-radius: 0.4rem;

  cursor: pointer;
  margin-left: 1.5rem;
  margin-top: 1rem;
}
.tagContainer{
 margin:5px;
}
.blue{
  color:white;
  padding: 0.2rem;
  display: inline-block;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  background-color:blue;
}
.orange{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:orangered
}
.yellow{
  text-align: center;
  display: inline-block;
  padding:0.3rem;
  margin:0.4rem;
  background-color:yellow;
  color:black;
}
.green{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:green;
}
.pink{
  
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:plum;
}
.purple{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:purple;
}
.darkred{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:darkred;
}
.lightblue{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:lightskyblue
}

.red{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:red;
}
.grey{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:grey;
}

.blue:hover{

  box-shadow: -5px 5px white, 5px 5px white;
}
.orange:hover{

  box-shadow: -5px 5px white, 5px 5px white;

}
.yellow:hover{

  box-shadow: -5px 5px white, 5px 5px white;
}
.green:hover{

  box-shadow: -5px 5px white, 5px 5px white;
}
.pink:hover{

  box-shadow: -5px 5px white, 5px 5px white;
}
.red:hover{

  box-shadow: -5px 5px white, 5px 5px white;
}
.grey:hover{

  box-shadow: -5px 5px white, 5px 5px white;
}
.lightblue:hover{

  box-shadow: -5px 5px white, 5px 5px white;
}
.darkred:hover{

  box-shadow: -5px 5px white, 5px 5px white;
}
.purple:hover{

  box-shadow: -5px 5px white, 5px 5px white;
}


