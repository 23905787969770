@media (min-width: 200px) and (max-width: 480px) {
  body #text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: large;
    width: 70vw;
  }
  body #about {
    width:75vw;
  }
}

#aboutcontainer {
  display: flex;
  flex-direction: column;
}
#about {
  color: black;
  text-align: center;
  background-color: white;
  width:10vw;
  margin-left:auto;
  margin-right: auto;

}
#face {
  height: 80vh;

  display: block;

  margin-left: auto;
  margin-right: auto;
}
#text {
  margin-bottom: 5rem;
  color: white;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  padding: 20px;
  font-size: larger;
  font-family: "Courier New", Courier, monospace;
  border-width: 10px;
  border-color: white;
  margin-top:0;
  box-shadow: -5px 5px white, 5px 5px white;
  transition:.6s ease;
  
}
#text:hover{

  box-shadow: -17px 17px white, 17px 17px white;
}
.highmetric_link{
  color:#26890d;
  text-decoration: none;
  background-color: white;

}
.highmetric_link:hover{
  text-decoration: underline;
  
}