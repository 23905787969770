@media (min-width: 200px) and (max-width: 480px) {
  body #projects {
    font-size: large;
    color: white;
    position: absolute;
    top: 6vh;
    left: 5vw;
    width: 60px;
    cursor: pointer;
    animation-name: glitch-original, floating;
    animation-iteration-count: 1, infinite;
    animation-timing-function: ease-in, ease;
    animation-duration: 2s, 2s;
    animation-direction: alternate;
  }
  body #title {
    position: -webkit-sticky;
    position: sticky;
    font-size: 40px;
    background-color: black;
    margin-top: -30vh;
    margin-bottom: 30vh;
    width: 90vw;
    top: 0;
    left: 0vw;
    right: 0vw;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }
 body #Skyline{
   
    width:75vw;
  
  
  }
  body #project-title{
   
    width:75vw;
  
  
  }
  body #cert-title{
   
    width:75vw;
  
  
  }
 body #tagcontainer {

    margin-right:0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  
  }
  body #tag{
    font-size: medium;
    font-family: "Courier New", Courier, monospace;
   align-items: center;
   margin:0.5rem;
  }
  body .resume {
    font-size: large;
    color: white;
    position: absolute;
    top: 6vh;
    right: 13vw;
    width: 60px;
    cursor: pointer;
    animation-name: glitch-original, floating;
    animation-iteration-count: 1, infinite;
    animation-timing-function: ease-in, ease;
    animation-duration: 2s, 2s;
    animation-direction: alternate;
  }
  body .contactStyle {
    font-size: large;
    color: white;
    position: absolute;
    top: 4vh;
    right: 42vw;
    width: 60px;
    cursor: 'pointer';
    animation-name: glitch-original, floating;
    animation-iteration-count: 1, infinite;
    animation-timing-function: ease-in, ease;
    animation-duration: 2s, 2s;
    animation-direction: alternate;
  }
  body .code{
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top:150%;
    margin-left:20%;
    
  }

  body .threescene {
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
  }

  body #pdf {
    display: block;
    width: 90vw;
    height: 100vh;

    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
  }
}
/* 
  ##Device = Desktops
  ##Screen = 1000px to higher resolution desktops
*/

@media (min-width: 1800px) {
  body #projects {
    font-size: x-large;
    color: white;
    position: absolute;
    top: 40vh;
    left: 20vh;
    width: 60px;
    z-index: 5;
    display: block;
    animation-name: glitch-original, floating;
    animation-iteration-count: 1, infinite;
    animation-timing-function: ease-in, ease;
    animation-duration: 2s, 2s;
    animation-direction: alternate;
    cursor: pointer;
  }
  body #title {
    position: -webkit-sticky;
    position: sticky;
    font-size: 80px;
    background-color: black;
    width: 70vh;
    top: 0;
    margin-top: -30vh;
    margin-bottom: 35vh;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
    cursor: pointer;
  }
  body #subtitle {
    position: absolute;
    font-size: 25px;
    background-color: black;
    width: 60vh;
    top: 90vh;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  body .resume {
    cursor: pointer;
    font-size: x-large;
    color: white;
    position: absolute;
    opacity: 1;
    top: 40vh;
    left: 80vw;
    width: 60px;
    z-index: 5;
    display: block;
    opacity: 1;
    animation-name: glitch-original, floating;
    animation-iteration-count: 1, infinite;
    animation-timing-function: ease-in, ease;
    animation-duration: 2s, 2s;
    animation-direction: alternate;
  }
  body .contactStyle {
    cursor: 'pointer';
    font-size: x-large;
    color: white;
    position: absolute;
    opacity: 1;
    top: 3vh;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 60px;
    z-index: 5;
    display: block;
    opacity: 1;
    animation-name: glitch-original, floating;
    animation-iteration-count: 1, infinite;
    animation-timing-function: ease-in, ease;
    animation-duration: 2s, 2s;
    animation-direction: alternate;
  }
  #pdf {
    display: block;
    width: 70vw;
    height: 100vh;

    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1800px) and (min-width: 600px) {
  body #projects {
    font-size: x-large;
    color: white;
    position: absolute;
    top: 40vh;
    left: 10vh;
    width: 60px;
    z-index: 5;
    display: block;
    animation-name: glitch-original, floating;
    animation-iteration-count: 1, infinite;
    animation-timing-function: ease-in, ease;
    animation-duration: 2s, 2s;
    animation-direction: alternate;
    cursor: pointer;
  }
  body #title {
    position: -webkit-sticky;
    position: sticky;
    font-size: 50px;
    background-color: black;
    width: 70vh;
    top: 0vh;
    margin-top: -30vh;
    margin-bottom: 35vh;
    left: 0;
    right: 0;
    z-index: 5;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }
  body #subtitle {
    position: absolute;
    font-size: 20px;
    background-color: black;
    width: 60vh;
    top: 90vh;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  body .resume {
    cursor: pointer;
    font-size: x-large;
    color: white;
    position: absolute;
    opacity: 1;
    top: 40vh;
    left: 80vw;
    width: 60px;
    z-index: 5;
    display: block;
    opacity: 1;
    animation-name: glitch-original, floating;
    animation-iteration-count: 1, infinite;
    animation-timing-function: ease-in, ease;
    animation-duration: 2s, 2s;
    animation-direction: alternate;
  }
  body .contactStyle {
    cursor: 'pointer';
    font-size: x-large;
    color: white;
    position: absolute;
    opacity: 1;
    top: 3vh;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 60px;
    z-index: 100;
    display: block;
    opacity: 1;
    animation-name: glitch-original, floating;
    animation-iteration-count: 1, infinite;
    animation-timing-function: ease-in, ease;
    animation-duration: 2s, 2s;
    animation-direction: alternate;
  }
  #pdf {
    display: block;
    width: 70vw;
    height: 100vh;

    margin-left: auto;
    margin-right: auto;
  }
}
#Skyline{
  margin-top: 15vh;
 position: absolute;
 left: 0;
    right: 0;
    z-index: 5;
    margin-left: auto;
    margin-right: auto;
  font-size: x-large;
  color: black;
  text-align: center;
  background-color: white;
  width:25vw;


}
#project-title{
  color: black;
  text-align: center;
  background-color: white;
  width:10vw;
  margin-left:auto;
  margin-right: auto;
}
#cert-title{
  color: black;
  text-align: center;
  background-color: white;
  width:10vw;
  margin-left:auto;
  margin-right: auto;
}
.introSlide {
  opacity: 1;
  background-color: black;
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  pointer-events: none;
  animation: fadeOut;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}
.introText {
  margin: 0;
  font-size: xx-large;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  animation: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.footerText {
  font-family: "Courier New", Courier, monospace;
  margin-top: 8rem;
  margin-bottom: 60;
}
.footerDivider {
  bottom: 60;
}
#subtitle {
  opacity: 0;
  animation-name: fadeIn;
  animation-delay: 3s, 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1, infinite;
  animation-timing-function: ease-in, ease;
  animation-duration: 5s, 9s;
}
.contactStyle:hover,
.resume:hover,
#projects:hover {
  opacity: 1;
  animation: glitch-original 0.85s;
  animation-iteration-count: infinite;
}

.threescene {
  opacity: 1;
}
#title {
  opacity: 1;
  animation-name: glitch, glitch;
  animation-iteration-count: 1, infinite;
  animation-timing-function: ease-in, ease;
  animation-delay: 0s, 5s;
  animation-duration: 10s, 18s;
}
.code{
  
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top:40%;
  margin-left:20%;
}
#rootmain {
  vertical-align: middle;
}
#projectsection {
  margin-bottom: 5rem;
}
#projectcontainer {
  vertical-align: middle;
  display: flex;
  flex-wrap: wrap;
}
#tagcontainer {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right:2rem;
  margin-left: 2rem;
  margin-bottom: 2rem;

}
#tag{
  font-size: larger;
  font-family: "Courier New", Courier, monospace;
 align-items: center;
 margin:0.5rem;
}
.loadingDots:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}

@keyframes floating {
  0% {
    transform: translateY(0.3rem);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes glitch {
  0.333% {
    text-shadow: 3px 3px green, -2px- 2px cyan;
    text-decoration: underline;
  }
  0.667% {
    text-shadow: -3px-3px green, 2px 2px cyan;
    text-decoration: none;
  }
  1% {
    text-shadow: -4px -3px red, 4px 2px blue;
  }
  1.333% {
    text-shadow: 3px 3px green, -2px -2px cyan;
  }
  1.667% {
    text-shadow: -3px -3px green, 2px 2px green;
    color: black;
  }
  2% {
    text-shadow: 3px 3px green, -2px -2px cyan;
  }
  2.333% {
    text-shadow: -1px -1px white, 1px 1px white;
    color: black;
  }
  2.667% {
    text-shadow: -6px -3px green, 6px 2px cyan;
    color: white;
  }
  3% {
    text-shadow: -5px -3px green, 5px 2px cyan;
    text-decoration: underline;
  }
  3.333% {
    text-shadow: -3px -3px green, 3px 2px cyan;
    text-decoration: none;
  }
  3.667% {
    text-shadow: -5px -3px green, 5px 2px yellow;
  }
  4% {
    text-shadow: -0px -3px purple, 0px 2px cyan;
  }
  4.333% {
    text-shadow: 0px 0px green, 0px 0px cyan;
  }
  4.667% {
    text-shadow: 2px 0px green, 2x 0px cyan;
    text-decoration: line-through;
  }
  5% {
    text-shadow: 0px 8px green, 0px 8px cyan;
    text-decoration: none;
  }
  5.333% {
    text-shadow: 0px 0px green, 0px 0px cyan;
  }
  5.667% {
    text-shadow: -4px 0px purple, -9px 0px yellow;
    text-decoration: overline;
  }
  6% {
    text-shadow: 0px -5px green, 0px -4px cyan;
    text-decoration: none;
  }
  6.333% {
    text-shadow: 0px 0px green, 0px 0px cyan;
  }
  6.667% {
    text-shadow: 0px 0px green, 0px 0px cyan;
  }
  100% {
    text-shadow: 0px 0px green, 0px 0px cyan;
  }
}

@keyframes glitch-original {
  5% {
    text-shadow: 3px 3px green, -2px -2px cyan;
    text-decoration: underline;
  }

  10% {
    text-shadow: -3px -3px green, 2px 2px cyan;
    text-decoration: none;
  }
  15% {
    text-shadow: -4px -3px red, 4px 2px blue;
  }
  20% {
    text-shadow: 3px 3px green, -2px -2px cyan;
  }
  25% {
    text-shadow: -3px -3px green, 2px 2px green;
    color: black;
  }
  30% {
    text-shadow: 3px 3px green, -2px -2px cyan;
  }
  35% {
    text-shadow: -1px -1px white, 1px 1px white;
    color: black;
  }
  40% {
    text-shadow: -6px -3px green, 6px 2px cyan;
    color: white;
  }
  45% {
    text-shadow: -5px -3px green, 5px 2px cyan;

    text-decoration: underline;
  }
  50% {
    text-shadow: -3px -3px green, 3px 2px cyan;

    text-decoration: none;
  }
  55% {
    text-shadow: -5px -3px green, 5px 2px yellow;
  }
  60% {
    text-shadow: -0px -3px purple, 0px 2px cyan;
  }
  65% {
    text-shadow: 0px 0px green, 0px 0px cyan;
  }
  70% {
    text-shadow: 2px 0px green, 2x 0px cyan;
    text-decoration: line-through;
  }
  75% {
    text-shadow: 0px 8px green, 0px 8px cyan;
    text-decoration: none;
  }
  80% {
    text-shadow: 0px 0px green, 0px 0px cyan;
  }
  85% {
    text-shadow: -4px 0px purple, -9px 0px yellow;
    text-decoration: overline;
  }
  90% {
    text-shadow: 0px -5px green, 0px -4px cyan;
    text-decoration: none;
  }
  95% {
    text-shadow: 0px 0px green, 0px 0px cyan;
  }
  100% {
    text-shadow: 0px 0px green, 0px 0px cyan;
  }
}


.blue{
  color:white;
  padding: 0.2rem;
  display: inline-block;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  background-color:blue;
  cursor: pointer;
}
.orange{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:orangered;
  cursor: pointer;
}
.yellow{
  text-align: center;
  display: inline-block;
  padding:0.3rem;
  margin:0.4rem;
  background-color:yellow;
  color:black;
  cursor: pointer;
}
.green{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:green;
  cursor: pointer;
}
.pink{
  
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:plum;
  cursor: pointer;
}
.purple{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:purple;
  cursor: pointer;
}
.darkred{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:darkred;
  cursor: pointer;
}
.lightblue{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:lightskyblue;
  cursor: pointer;
}

.red{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:red;
  cursor: pointer;
}
.grey{
  color:white;
  text-align: center;
  padding:0.3rem;
  margin:0.4rem;
  display: inline-block;
  background-color:grey;
  cursor: pointer;
}

.selected{
    box-shadow: -5px 5px white, 5px 5px white;

  
}
